<template>
  <div class="franchise-cnt">
    <header class="inner-head">
      <div class="left-col">
        <span class="icon-btn" @click="$router.go(-1)">
          <BackIcon />
        </span>
        <h1>&nbsp;</h1>
      </div>
    </header>
    <section class="mid-cnt">
      <div class="frenchise-img">
        <img src="@/assets/images/frenchise-img.jpg" alt="" />
      </div>

      <div class="info-databox">
        <h2>Mall91 Franchise</h2>
        <p>
          अगर आप Mall91 की फ्रैंचाइज़ी लेते है तो आप सबसे ऊपर रहेंगे किसी भी
          एक्स्ट्रा डिस्काउंट पाने में
        </p>
        <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip.
        </p> -->
      </div>

      <div class="info-databox">
        <h2>फ्रैंचाइज़ी लेने के फायदे</h2>
        <ul class="point-list">
          <li>
            <span class="check-icon">
              <check-mark-icon />
            </span>
            किसी भी प्रीमियम फ्रैंचाइज़ी को पहले एक्सेस दिया जाएगा।
          </li>
          <li>
            <span class="check-icon">
              <check-mark-icon />
            </span>
            24x7 विशेष सहायता सेवा।
          </li>
          <li>
            <span class="check-icon">
              <check-mark-icon />
            </span>
            विशेष छूट, कैशबैक और कई अन्य अद्भुत सुविधाएं।
          </li>
        </ul>
      </div>
    </section>
    <footer class="ftr-btm">
      <div class="txt-note">
        आपको फ्रैंचाइज़ी लेने के ₹1000 का भुगतान करना पड़ेगा
      </div>
      <button
        class="button"
        :class="{ isFranchise: $route.query.is_partner_franchise == 'true' }"
        @click="$router.push('/payment-form')"
        :disabled="$route.query.is_partner_franchise == 'true'"
      >
      {{$route.query.is_partner_franchise == 'true'?"PAYMENT DONE":"₹1000 भुगतान करे"}}
        
      </button>
    </footer>
  </div>
</template>
<script>
import BackIcon from "@/components/svgIcons/BackIcon";
import CheckMarkIcon from "@/components/svgIcons/CheckMarkIcon";

export default {
  name: "PartnerFranchise",
  components: {
    BackIcon,
    CheckMarkIcon,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/partner-franchise.scss";
</style>
